<template>
  <div>
    <v-btn
      small
      outlined
      to="/worker"
      sele
      class="mt-4 mb-4"
      color="blue"
      :exact="true"
    >
      К списку поверок
    </v-btn>

    <Heading :title="(id ? 'Редактирование' : 'Добавление') + ' поверки'"/>

    <VerificationForm
      :id="id"
    />
  </div>
</template>

<script>
import VerificationForm from '@/components/Verifications/Form'

export default {
  components: {
    VerificationForm
  },

  props: {
    id: {
      default: false
    }
  }
}
</script>
